/* src/components/AboutUs.css */
.about-us-container {
  display: flex;
  align-items: center;
  padding: 40px;
  gap: 20px;
}

.about-us-text {
  flex: 1;
  padding-right: 20px;
}

.about-us-text h2 {
  color: #002060;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.about-us-text h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #002060;
}

.about-us-text p {
  font-size: 1em;
  color: #333;
  margin-bottom: 20px;
}

.about-us-text ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.about-us-text ul li {
  font-size: 1em;
  color: #002060;
  margin-bottom: 10px;
}

.read-more-button {
  padding: 10px 20px;
  background-color: #002060;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.about-us-image {
  position: relative;
  flex: 1;
}

.about-us-image img {
  width: 100%;
  border-radius: 10px;
}

.jobs-done {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.jobs-done span {
  font-size: 1.5em;
  font-weight: bold;
}

.jobs-done p {
  font-size: 1em;
  margin: 0;
}

@media (max-width: 768px) {
  .about-us-container {
    flex-direction: column;
  }
}
