.header {
  position: relative; /* Для правильного позиционирования псевдоэлемента */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-image: url("../../assets/Applince-repair.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  color: white;
  padding-left: 5%;
}

.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный цвет */
  z-index: 1; /* Помещаем под контент */
}

.header-content {
  position: relative; /* Чтобы текст отображался поверх затемнения */
  max-width: 50%;
  z-index: 2; /* Выше затемнения */
}

.header-content h1 {
  color: #ffffff; /* Белый цвет текста для лучшей видимости */
  font-size: 90px;
  margin-bottom: 20px;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.header-buttons .read-more,
.header-buttons .contact-us {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0 40px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  height: 50px;
  border-radius: 50px;
  width: 170px;
  text-decoration: none;
}

.header-buttons .read-more {
  background-color: #db2626;
}

.header-buttons .contact-us {
  background-color: #070a4f;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    text-align: center;
    padding: 15px;
    height: auto;
  }

  .header-content {
    max-width: 90%;
    margin-bottom: 20px;
    text-align: start;
  }

  .header-content h1 {
    font-size: 50px;
    padding-top: 100px;
  }

  .header-buttons {
    flex-direction: row;
    gap: 10px;
  }

  .header-buttons .read-more,
  .header-buttons .contact-us {
    width: 30%;
    padding: 10px;
    height: 40px;
  }
}

/* Small Mobile Styles */
@media (max-width: 480px) {
  .header-content h1 {
    font-size: 36px;
  }

  .header-buttons .read-more,
  .header-buttons .contact-us {
    font-size: 14px;
    padding: 8px;
  }
}
