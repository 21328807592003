/* src/components/ServiceCard.css */
.service-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  transition: transform 0.2s;
  width: 270px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .service-card {
    width: 30%;
    height: 230px;
  }
}

.service-card:hover {
  transform: scale(1.05);
}

.service-image {
  width: 150px;
  height: auto;
  margin-bottom: 16px;
}

.service-title {
  font-size: 1.2em;
  color: #333;
}
